<section class="section banner" id="banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div class='banner-title'>
                    <h2>Artificial Intelligence Integrations</h2>
                    <h3 [innerHTML]='"Banner.Slogan" | translate'></h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a (click)="scroll('contact')" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>
